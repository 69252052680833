import React, { useState, useEffect } from 'react';
import Layout from './SenderForm/Layout/Layout';
import Request from './SenderForm/Workspace/Request/RequestPanel';
import Response from './SenderForm/Workspace/Response/ResponsePanel';
import ResponseCondition from './SenderForm/ResponseCondition';
import MessageIdForm from './SenderForm/MessageIdForm';

const SenderForm = ({ sender }) => {
  const [senderName, setSenderName] = useState(sender ? sender.name : '');
  const [testPhoneNumber, setTestPhoneNumber] = useState(sender ? sender.test_phone : '');
  const [testMessage, setTestMessage] = useState(sender ? sender.test_message : '');

  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    request: {}, // Данные из компонента Request
    responseCondition: {}, // Данные из компонента ResponseCondition
    messageIdForm: {} // Данные из компонента MessageIdForm
    // Добавьте другие необходимые поля здесь
  });

  useEffect(() => {
    // Инициализация formData при монтировании компонента
    setFormData({
      request: {
        url: sender ? sender.url : '',
        headers: sender ? sender.headers : [],
        reqMethod: sender ? sender.request_type : '',
        requestBody: sender ? JSON.stringify(sender.request_body) : '',
        queryParams: sender ? sender.query_params : [],
      },
      responseCondition: sender ? sender.search_conditions.responseCondition : {},
      messageIdForm: sender ? sender.message_id_find : {},
      // Добавьте другие необходимые поля здесь
    });
  }, [sender]);

  const handleSaveSender = async () => {
    const isUpdate = sender && sender.id; // Проверяем, существует ли 'id' у 'sender'
    const endpoint = isUpdate ? `/senders/${sender.id}` : "/senders";
    const method = isUpdate ? 'PUT' : 'POST';

    console.log('formData:', formData);

    const data = {
      name: senderName, // Это пример, замените на реальные данные
      url: formData.request.url,
      headers: formData.request.headers,
      request_type: formData.request.reqMethod,
      request_body: JSON.parse(formData.request.requestBody),
      query_params: formData.request.queryParams,
      search_conditions: {
        responseCondition: formData.responseCondition
      },
      message_id_find: {
        // Данные из компонента MessageIdForm
        data_type: formData.messageIdForm.dataType,
        selector: formData.messageIdForm.selector
      },
      // Добавьте другие необходимые поля здесь
      test_phone: testPhoneNumber,
      test_message: testMessage
    };

    console.log("DATA:", data);

  
    try {
      const csrfToken = document.querySelector('[name=csrf-token]').content;
      const response = await fetch(endpoint, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        },
        body: JSON.stringify(data)
      });
  
      if (response.ok) {
        window.location.href = '/senders'; // Редирект на senders#index
      } else {
        // Обработка ошибок, если запрос не успешен
        console.error('Ошибка запроса:', response.statusText);
      }
    } catch (error) {
      console.error('Ошибка:', error);
    }
  };

  // Функция для обновления данных из дочерних компонентов
  const updateFormData = (key, data) => {
    console.log('updateFormData:', key, data);
    setFormData((prev) => ({ ...prev, [key]: data }));
  };

  return (
    <>
        <Layout>
          <div className="mb-4">
            <label htmlFor="senderName" className="block text-gray-700 text-sm font-bold mb-2">
              Sender name
            </label>
            <input
              type="text"
              id="senderName"
              value={senderName}
              onChange={(e) => setSenderName(e.target.value)}
              placeholder="Enter Sender Name"
              className="px-4 py-1.5 w-full border border-gray-300 rounded-md  hover:border-orange-500 focus:outline-orange-500"
            />
          </div>
          <Request
            setResponse={setResponse}
            setLoading={setLoading}
            senderData={sender}
            updateFormData={data => updateFormData('request', data)}
            testPhoneNumber={testPhoneNumber}
            setTestPhoneNumber={setTestPhoneNumber}
            testMessage={testMessage}
            setTestMessage={setTestMessage}
          />
          <Response response={response} loading={loading} />
          <ResponseCondition
            response={response}
            updateFormData={data => updateFormData('responseCondition', data)}
            senderData={sender}
          />
          <MessageIdForm
            response={response}
            updateFormData={data => updateFormData('messageIdForm', data)}
            senderData={sender}
          />

          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 mt-4"
            onClick={() => handleSaveSender()}
          >
            Save Sender
          </button>
        </Layout>
    </>
  );
};

export default SenderForm;
