import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';

const DepositsTable = ({ initialDeposits, initialTotalPages, initialCurrentPage, initialTotalCount, statuses }) => {
  const [deposits, setDeposits] = useState(initialDeposits);
  const [totalPages, setTotalPages] = useState(initialTotalPages);
  const [currentPage, setCurrentPage] = useState(initialCurrentPage);
  const [totalCount, setTotalCount] = useState(initialTotalCount);
  const [filters, setFilters] = useState({
    customer_id: '',
    status: '',
    start_date: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const statusOptions = [
    { value: '', label: 'All Statuses' },
    ...statuses.map(status => ({ value: status, label: status }))
  ];

  const fetchDeposits = async (page, appliedFilters) => {
    setIsLoading(true);
    const params = new URLSearchParams({ 
      page, 
      ...appliedFilters
    });
    try {
      const response = await fetch(`/api/v1/deposits?${params}`);
      const data = await response.json();
      setDeposits(data.deposits);
      setTotalPages(data.total_pages);
      setCurrentPage(data.current_page);
      setTotalCount(data.total_count);
    } catch (error) {
      console.error('Error fetching deposits:', error);
    }
    setIsLoading(false);
  };

  const handleFilterChange = (name, value) => {
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleApplyFilters = () => {
    fetchDeposits(1, filters);
  };

  const handleResetFilters = () => {
    setFilters({
      customer_id: '',
      status: '',
      start_date: '',
    });
    fetchDeposits(1, {});
  };

  const handlePageChange = (newPage) => {
    fetchDeposits(newPage, filters);
  };

  const getStatusColor = (status) => {
    switch(status.toLowerCase()) {
      case 'ok': return 'text-green-600 bg-green-100';
      case 'pending': return 'text-gray-600 bg-gray-100';
      case 'rejected': return 'text-red-600 bg-red-100';
      default: return 'text-gray-600 bg-gray-100';
    }
  };

  return (
    <div className="w-full">
      <div className="mb-4 flex items-center space-x-2">
        <input
          type="text"
          placeholder="Customer ID"
          value={filters.customer_id}
          onChange={(e) => handleFilterChange('customer_id', e.target.value)}
          className="border p-2 rounded flex-grow"
        />
        <Select
          className="w-40"
          placeholder="Status"
          isClearable
          options={statusOptions}
          value={statusOptions.find(option => option.value === filters.status) || null}
          onChange={(option) => handleFilterChange('status', option ? option.value : '')}
        />
        <input
          type="date"
          value={filters.start_date}
          onChange={(e) => handleFilterChange('start_date', e.target.value)}
          className="border p-2 rounded w-40"
        />
        <button
          onClick={handleApplyFilters}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Apply Filters
        </button>
        <button
          onClick={handleResetFilters}
          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
        >
          Reset Filters
        </button>
      </div>

      {isLoading ? (
        <div className="text-center py-4">Loading...</div>
      ) : (
        <>
          <div className="w-full overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3">Transaction ID</th>
                  <th scope="col" className="px-6 py-3">Status</th>
                  <th scope="col" className="px-6 py-3">Customer ID</th>
                  <th scope="col" className="px-6 py-3">Amount</th>
                  <th scope="col" className="px-6 py-3">Currency</th>
                  <th scope="col" className="px-6 py-3">Agent</th>
                  <th scope="col" className="px-6 py-3">Subagent</th>
                  <th scope="col" className="px-6 py-3">Payment Start Date</th>
                  <th scope="col" className="px-6 py-3">Payment End Date</th>
                </tr>
              </thead>
              <tbody>
                {deposits.map((deposit) => (
                  <tr key={deposit.transaction_id} className="bg-white border-b hover:bg-gray-50">
                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">{deposit.transaction_id}</td>
                    <td className={`px-6 py-4 ${getStatusColor(deposit.transaction_status)}`}>{deposit.transaction_status}</td>
                    <td className="px-6 py-4">{deposit.customer_id}</td>
                    <td className="px-6 py-4">{deposit.amount}</td>
                    <td className="px-6 py-4">{deposit.currency}</td>
                    <td className="px-6 py-4">{deposit.agent}</td>
                    <td className="px-6 py-4">{deposit.subagent}</td>
                    <td className="px-6 py-4">{new Date(deposit.payment_start_date).toLocaleString()}</td>
                    <td className="px-6 py-4">{new Date(deposit.payment_end_date).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden">
              <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
                Previous
              </button>
              <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
                Next
              </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing page <span className="font-medium">{currentPage}</span> of <span className="font-medium">{totalPages}</span> | Total deposits: <span className="font-medium">{totalCount}</span>
                </p>
              </div>
              <div>
                <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                  <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DepositsTable;