// components/StepNavigation.jsx
import React from 'react';

const StepNavigation = ({ currentStep, onStepChange, isFormValid }) => {
  const steps = [
    { id: 1, label: 'Audience' },
    { id: 2, label: 'Settings' },
    { id: 3, label: 'Message' }
  ];

  const getStepClassName = (stepId) => {
    const baseClasses = "px-4 py-2 rounded font-medium transition-all duration-200 relative";
    
    // Если это текущий шаг
    if (stepId === currentStep) {
      return `${baseClasses} bg-blue-500 text-white`;
    }
    
    // Если шаг доступен (предыдущие шаги выполнены и форма валидна)
    if (stepId < currentStep || (stepId === currentStep + 1 && isFormValid)) {
      return `${baseClasses} bg-gray-200 text-gray-700 hover:bg-gray-300 cursor-pointer`;
    }
    
    // Если шаг недоступен
    return `${baseClasses} bg-gray-100 text-gray-400 cursor-not-allowed opacity-50`;
  };

  const getStepIndicator = (stepId) => {
    // Если шаг пройден
    if (stepId < currentStep) {
      return (
        <div className="absolute -top-3 -right-3">
          <svg 
            className="w-6 h-6 text-green-500 bg-white rounded-full"
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" 
            />
          </svg>
        </div>
      );
    }
    return null;
  };

  const handleStepClick = (stepId) => {
    // Разрешаем переход только на доступные шаги
    if (stepId < currentStep || (stepId === currentStep + 1 && isFormValid)) {
      onStepChange(stepId);
    }
  };

  return (
    <div className="mb-8">
      <div className="flex justify-between items-center relative">
        {/* Линия прогресса */}
        <div className="absolute h-1 bg-gray-200 left-0 right-0 top-1/2 -translate-y-1/2 z-0">
          <div 
            className="h-full bg-blue-500 transition-all duration-300"
            style={{ width: `${((currentStep - 1) / (steps.length - 1)) * 100}%` }}
          />
        </div>

        {/* Шаги */}
        <div className="flex justify-between w-full relative z-10">
          {steps.map((step) => (
            <div key={step.id} className="flex flex-col items-center">
              <button
                onClick={() => handleStepClick(step.id)}
                className={getStepClassName(step.id)}
                disabled={step.id > currentStep + 1 || (step.id === currentStep + 1 && !isFormValid)}
              >
                {step.label}
                {getStepIndicator(step.id)}
              </button>
              
              {/* Индикатор текущего шага */}
              {step.id === currentStep && (
                <div className="mt-2 text-xs font-medium text-blue-600">
                  Current Step
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Дополнительная информация о прогрессе */}
      <div className="mt-4 text-center text-sm text-gray-600">
        Step {currentStep} of {steps.length}
        {!isFormValid && currentStep === 1 && (
          <p className="text-orange-500 mt-1">
            Please complete audience selection to proceed
          </p>
        )}
      </div>
    </div>
  );
};

export default StepNavigation;