// steps/CampaignSettings.jsx
import React, { useEffect } from 'react';
import Select from 'react-select';

const CampaignSettings = ({ formData, updateFormData, senders, setIsFormValid }) => {
  const handleInputChange = (field, value) => {
    updateFormData({ [field]: value });
  };

  // Проверяем заполнение обязательных полей
  useEffect(() => {
    const isValid = !!(
      formData.name?.trim() && // Проверяем наличие имени кампании
      formData.sender         // Проверяем выбор сендера
    );
    setIsFormValid(isValid);
  }, [formData.name, formData.sender, setIsFormValid]);

  // Правильно преобразуем список сендеров для компонента Select
  const senderOptions = senders.map(sender => ({
    value: sender.value,
    label: sender.label
  }));

  return (
    <div className="space-y-6">
      {/* Campaign Name */}
      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
          Campaign Name *
        </label>
        <input
          id="name"
          type="text"
          value={formData.name}
          onChange={(e) => handleInputChange('name', e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Enter campaign name"
        />
      </div>

      {/* Sender Selection */}
      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="sender">
          Sender *
        </label>
        <Select
          id="sender"
          value={formData.sender}
          onChange={(option) => handleInputChange('sender', option)}
          options={senderOptions}
          placeholder="Select sender"
          isSearchable
          className="text-gray-700"
        />
      </div>

      {/* Send Time Options */}
      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Send Time
        </label>
        <div className="space-y-4">
          <div className="flex items-center">
            <input
              type="radio"
              id="sendNow"
              value="now"
              checked={formData.sendTime === 'now'}
              onChange={(e) => handleInputChange('sendTime', e.target.value)}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
            />
            <label htmlFor="sendNow" className="ml-2 block text-gray-700">
              Send Now
            </label>
          </div>

          <div className="flex items-center">
            <input
              type="radio"
              id="sendLater"
              value="later"
              checked={formData.sendTime === 'later'}
              onChange={(e) => handleInputChange('sendTime', e.target.value)}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
            />
            <label htmlFor="sendLater" className="ml-2 block text-gray-700">
              Schedule for Later
            </label>
          </div>

          {formData.sendTime === 'later' && (
            <div className="ml-6">
              <input
                type="datetime-local"
                value={formData.scheduledTime}
                onChange={(e) => handleInputChange('scheduledTime', e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                min={new Date().toISOString().slice(0, 16)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CampaignSettings;