import React, { useState } from 'react';

const NavigationHeader = ({ currentUser, userEmail }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const username = userEmail ? userEmail.split('@')[0] : '';
  const isAdmin = currentUser?.role === 'admin';

  const handleLogout = (e) => {
    e.preventDefault();
    // Создаем форму для отправки DELETE запроса
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = '/users/sign_out';
    
    // Добавляем CSRF токен
    const csrfToken = document.querySelector('[name="csrf-token"]')?.content;
    if (csrfToken) {
      const csrfInput = document.createElement('input');
      csrfInput.type = 'hidden';
      csrfInput.name = 'authenticity_token';
      csrfInput.value = csrfToken;
      form.appendChild(csrfInput);
    }
    
    // Добавляем _method=delete для эмуляции DELETE запроса
    const methodInput = document.createElement('input');
    methodInput.type = 'hidden';
    methodInput.name = '_method';
    methodInput.value = 'delete';
    form.appendChild(methodInput);
    
    document.body.appendChild(form);
    form.submit();
  };

  return (
    <header className="flex flex-wrap md:justify-start md:flex-nowrap z-50 w-full bg-blue-600 sticky top-0 shadow-lg">
      <nav className="relative w-full md:flex md:items-center md:justify-between md:gap-3 mx-auto px-4 sm:px-6 lg:px-8 py-2">
        {/* Logo w/ Collapse Button */}
        <div className="flex items-center justify-between">
          <a className="flex-none font-semibold text-xl text-white focus:outline-none focus:opacity-80 hover:text-blue-100 transition-colors" href="/" aria-label="Brand">
            ParipesaRetention
          </a>

          {/* Mobile Menu Button */}
          <button
            type="button"
            className="md:hidden relative size-9 flex justify-center items-center text-sm font-semibold rounded-lg border border-white/50 text-white hover:bg-white/10 focus:outline-none focus:bg-white/10"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            aria-expanded={isMobileMenuOpen}
            aria-label="Toggle navigation"
          >
            {!isMobileMenuOpen ? (
              <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            ) : (
              <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            )}
          </button>
        </div>

        {/* Menu Content */}
        <div className={`${isMobileMenuOpen ? 'block' : 'hidden'} md:block w-full md:w-auto`}>
          <div className="py-2 md:py-0 flex flex-col md:flex-row md:items-center md:justify-end gap-0.5 md:gap-1">
            {currentUser && (
              <>
                <a className="p-2 flex items-center text-sm text-white/80 hover:text-white focus:outline-none focus:text-white transition-colors" href="/campaigns">
                  <svg className="shrink-0 size-4 me-3 md:me-2 block md:hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M4 22h16a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v16a2 2 0 0 1-2 2Z"/>
                    <path d="M18 14h-8"/>
                    <path d="M15 18h-5"/>
                    <path d="M10 6h8v4h-8V6Z"/>
                  </svg>
                  Campaigns
                </a>

                {isAdmin && (
                  <>
                    <a className="p-2 flex items-center text-sm text-white/80 hover:text-white focus:outline-none focus:text-white transition-colors" href="/customers">
                      <svg className="shrink-0 size-4 me-3 md:me-2 block md:hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"/>
                        <circle cx="9" cy="7" r="4"/>
                        <path d="M23 21v-2a4 4 0 0 0-3-3.87"/>
                        <path d="M16 3.13a4 4 0 0 1 0 7.75"/>
                      </svg>
                      Customers
                    </a>

                    <a className="p-2 flex items-center text-sm text-white/80 hover:text-white focus:outline-none focus:text-white transition-colors" href="/deposits">
                      <svg className="shrink-0 size-4 me-3 md:me-2 block md:hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <rect x="2" y="5" width="20" height="14" rx="2"/>
                        <line x1="2" y1="10" x2="22" y2="10"/>
                      </svg>
                      Deposits
                    </a>

                    <a className="p-2 flex items-center text-sm text-white/80 hover:text-white focus:outline-none focus:text-white transition-colors" href="/senders">
                      <svg className="shrink-0 size-4 me-3 md:me-2 block md:hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2"/>
                        <path d="M22 6v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6"/>
                        <path d="M2 6l10 7 10-7"/>
                      </svg>
                      Senders
                    </a>

                    <a className="p-2 flex items-center text-sm text-white/80 hover:text-white focus:outline-none focus:text-white transition-colors" href="/users">
                      <svg className="shrink-0 size-4 me-3 md:me-2 block md:hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/>
                        <circle cx="12" cy="7" r="4"/>
                      </svg>
                      Users
                    </a>
                  </>
                )}
              </>
            )}

            {/* User Menu */}
            <div className="relative flex flex-wrap items-center gap-x-1.5 md:ps-2.5 mt-1 md:mt-0 md:ms-1.5 before:block before:absolute before:top-1/2 before:-start-px before:w-px before:h-4 before:bg-white/30 before:-translate-y-1/2">
              {currentUser ? (
                <>
                  <div className="p-2 text-sm text-white/80">
                    <span className="hidden md:inline">{username}</span>
                  </div>
                  <button
                    onClick={handleLogout}
                    className="p-2 flex items-center text-sm text-white/80 hover:text-white focus:outline-none focus:text-white transition-colors"
                  >
                    <svg className="shrink-0 size-4 me-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"/>
                      <polyline points="16 17 21 12 16 7"/>
                      <line x1="21" y1="12" x2="9" y2="12"/>
                    </svg>
                    Sign out
                  </button>
                </>
              ) : (
                <a
                  href="/users/sign_in"
                  className="p-2 flex items-center text-sm text-white/80 hover:text-white focus:outline-none focus:text-white transition-colors"
                >
                  Sign in
                </a>
              )}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default NavigationHeader;