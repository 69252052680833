import React from 'react';

const ManualIdsInput = ({ value, onChange, onClear }) => {
 const handleInput = (e) => {
   const newValue = e.target.value;
   onChange(newValue);
 };

 const validateIds = (ids) => {
   const lines = ids
     .split('\n')
     .map(line => line.trim())
     .filter(line => line); // пропускаем пустые строки

   const invalidIds = lines.filter(id => !(/^\d+$/.test(id)));
   const validIds = lines.filter(id => /^\d+$/.test(id));
   
   return {
     invalidIds,
     validIds
   };
 };

 const { invalidIds, validIds } = validateIds(value);

 return (
   <div className="mt-6">
     <div className="flex justify-between items-center mb-2">
       <div>
         <label className="block text-gray-700 text-sm font-bold">
           Manual User IDs
         </label>
         <p className="text-sm text-gray-500 mt-1">
           Enter IDs (one per line)
         </p>
       </div>
       {value && (
         <button
           onClick={onClear}
           className="text-sm bg-red-50 text-red-600 hover:bg-red-100 px-3 py-1.5 rounded-md transition-colors duration-200"
         >
           Clear List
         </button>
       )}
     </div>
     
     <textarea
       value={value}
       onInput={handleInput}
       className="w-full h-40 p-3 border border-gray-300 rounded-md font-mono text-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent"
       placeholder="Enter IDs here (one per line)"
     />
     
     <div className="mt-2 space-y-2">
       {invalidIds.length > 0 && (
         <p className="text-sm text-red-500">
           Some IDs are invalid (must contain only numbers):
           <br />
           {invalidIds.join(', ')}
         </p>
       )}
       <div className="flex justify-end items-center text-sm">
         {validIds.length > 0 && (
           <span className="bg-blue-50 text-blue-600 px-2 py-1 rounded">
             {validIds.length} valid IDs
           </span>
         )}
       </div>
     </div>
   </div>
 );
};

export default ManualIdsInput;