import React from 'react';
import Navbar from './Navbar/Navbar';

export default function Layout({ children }) {
  return (
    <>
      <main className="bg-slate-200">
        <div className='flex w-full min-h-screen pt-[10px] mx-auto px-5 bg-white rounded'>
          <div className='mx-auto w-full'>{children}</div>
        </div>
      </main>
    </>
  );
}
