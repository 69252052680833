import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { convertKeyValueToObject } from '../../../../utils/helpers';
import UrlEditor from '../../Panes/RequestUrl/UrlEditor';
import RequestTabGroup from '../../Tab-Groups/RequestTabGroup';

const keyPairInitState = [
  {
    id: uuidv4(),
    keyItem: '',
    valueItem: '',
  },
];

export default function Request({ senderData, setResponse, setLoading, updateFormData, testPhoneNumber, setTestPhoneNumber, testMessage, setTestMessage  }) {
  
  const [url, setUrl] = useState('https://reqres.in/api/users');
  const [reqMethod, setReqMethod] = useState('GET');
  const [queryParams, setQueryParams] = useState(keyPairInitState);
  const [headers, setHeaders] = useState(keyPairInitState);
  const [body, setBody] = useState('{\n\t\n}');


  useEffect(() => {
    if (senderData) {

      // Установите начальное состояние, основываясь на данных sender
      setUrl(senderData.url || '');
      setReqMethod(senderData.request_type || 'GET');

      // Преобразование headers и queryParams, если они представлены в необходимом формате
      if (senderData.headers) {
        setHeaders(senderData.headers.map(header => ({
          id: header.id || uuidv4(),
          keyItem: header.keyItem || '',
          valueItem: header.valueItem || ''
        })));
      }

      if (senderData.query_params) {
        setQueryParams(senderData.query_params.map(queryParam => ({
          id: queryParam.id || uuidv4(),
          keyItem: queryParam.keyItem || '',
          valueItem: queryParam.valueItem || ''
        })));
      }

      if (senderData.request_body) {
        setBody(JSON.stringify(senderData.request_body, null, 2));
      }

    }
  }, [senderData]);


  const handleOnInputSend = async (e) => {
    setLoading(true);

    e.preventDefault();
    const requestBody = body.toString();

    // Собрать данные из формы (пример)
    const formData = {
      url,
      reqMethod,
      queryParams,
      headers,
      requestBody,
    };

    console.log('formData', formData);

    updateFormData({ url, reqMethod, queryParams, headers, requestBody });

    console.log('requestBody before parsing:', requestBody);

    let data;
    try {
      data = JSON.parse(requestBody);
    } catch (e) {
      console.error('JSON parsing error:', e);
      alert('Something is wrong with the JSON data.');
    }

    try {
      
      const response = await axios.post('/proxy', {
        url,
        method: reqMethod,
        headers: convertKeyValueToObject(headers),
        body: data,
        test_phone: testPhoneNumber,
        test_message: testMessage
      });

      setResponse(response);
    } catch (e) {
      console.log(e);
      setResponse(e);
    }

    setLoading(false);
  };
  return (
    <>
      <UrlEditor
        url={url}
        setUrl={setUrl}
        reqMethod={reqMethod}
        setReqMethod={setReqMethod}
        onInputSend={handleOnInputSend}
        testPhoneNumber={testPhoneNumber}
        setTestPhoneNumber={setTestPhoneNumber}
        testMessage={testMessage}
        setTestMessage={setTestMessage}
      />
      <RequestTabGroup
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        headers={headers}
        setHeaders={setHeaders}
        body={body}
        setBody={setBody}
      />
    </>
  );
}
