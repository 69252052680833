// steps/AudienceStep.jsx
import React, { useState } from 'react';
import AudienceFilters from '../components/AudienceFilters';
import ManualIdsInput from '../components/ManualIdsInput';
import SearchAudienceButton from '../components/SearchAudienceButton';

const AudienceStep = ({ formData, updateFormData, countries, setIsFormValid }) => {
  const [isManualMode, setIsManualMode] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSearch = async () => {
    setIsLoading(true);
    setError(null);
  
    try {
      let requestBody;
      
      if (formData.manualIds) {  // Проверяем наличие manual ids
        requestBody = {
          manual_ids: formData.manualIds
        };
      } else {
        requestBody = {
          filters: {
            country: formData.country?.value,  // Добавляем опциональную цепочку
            last_deposit_start: formData.lastDepositStart,
            last_deposit_end: formData.lastDepositEnd,
            deposits_count: formData.depositsCount,
            ban: formData.ban,
          }
        };
      }
  
      const response = await fetch('/api/v1/audience/count', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify(requestBody)
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch audience count');
      }
  
      const data = await response.json();
      updateFormData({ audienceCount: data.count });
      setIsFormValid(data.count > 0);
      setHasSearched(true);
    } catch (err) {
      console.error('Search error:', err);
      setError(err.message || 'Failed to fetch audience count');
      setIsFormValid(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearManualMode = () => {
    // Очищаем все данные формы
    updateFormData({
      manualIds: '',
      audienceCount: 0
    });
    setIsManualMode(false);
    setHasSearched(false);
    setIsSearchEnabled(false);
    setError(null);
    setIsFormValid(false);
  };

  const handleFiltersChange = (filters) => {
    updateFormData(filters);
    setHasSearched(false);
    checkSearchEnabled(filters);
  };

  const checkSearchEnabled = (filters) => {
    if (isManualMode) {
      setIsSearchEnabled(!!formData.manualIds);
      return;
    }

    const isEnabled = !!(
      filters.country &&
      filters.lastDepositStart &&
      filters.lastDepositEnd
    );
    setIsSearchEnabled(isEnabled);
  };


  const handleManualIdsChange = (value) => {
    updateFormData({ manualIds: value });
    
    // Проверяем только валидные ID (только цифры)
    const validIds = value
      .split('\n')
      .map(line => line.trim())
      .filter(line => line && /^\d+$/.test(line));
  
    // Активируем кнопку только если ВСЕ введенные непустые строки являются валидными ID
    const hasValidIds = validIds.length > 0;
    
    //setIsSearchEnabled(isValid);
    setIsManualMode(hasValidIds);
    setIsSearchEnabled(hasValidIds);
    setHasSearched(false);
  };

  return (
    <div className="space-y-6">
      <div className={`transition-opacity duration-200 ${isManualMode ? 'opacity-50 pointer-events-none' : ''}`}>
        <AudienceFilters
          formData={formData}
          countries={countries}
          onChange={handleFiltersChange}
          disabled={isManualMode}
        />
      </div>

      <ManualIdsInput
        value={formData.manualIds}
        onChange={handleManualIdsChange}
        onClear={handleClearManualMode}
      />

      <SearchAudienceButton
        isEnabled={isSearchEnabled}
        isLoading={isLoading}
        hasSearched={hasSearched}
        audienceCount={formData.audienceCount}
        error={error}
        onSearch={handleSearch}
      />
    </div>
  );
};

export default AudienceStep;