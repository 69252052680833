import React, { useRef, useEffect } from 'react';
import { EditorView, keymap } from '@codemirror/view';
import { EditorState, basicSetup } from '@codemirror/basic-setup';
import { defaultTabBinding } from '@codemirror/commands';
import { json } from '@codemirror/lang-json';

const basicExtensions = [
  basicSetup,
  keymap.of([defaultTabBinding]),
  json(),
  EditorState.tabSize.of(2),
];

export default function JsonEditorPanel({
  paneValue,
  setPaneValue,
  isEditable = true,
}) {
  const editorRef = useRef();
  const editorViewRef = useRef();

  useEffect(() => {
    if (!editorRef.current) return;

    if (!editorViewRef.current) {
      const startState = EditorState.create({
        doc: paneValue,
        extensions: [
          ...basicExtensions,
          EditorView.updateListener.of((update) => {
            if (update.docChanged) {
              setPaneValue(update.state.doc.toString());
            }
          }),
          EditorView.editable.of(isEditable),
        ],
      });

      editorViewRef.current = new EditorView({
        state: startState,
        parent: editorRef.current,
      });
    }

    return () => {
      editorViewRef.current?.destroy();
    };
  }, []);

  useEffect(() => {
    if (editorViewRef.current && paneValue !== editorViewRef.current.state.doc.toString()) {
      const update = editorViewRef.current.state.update({
        changes: { from: 0, to: editorViewRef.current.state.doc.length, insert: paneValue },
      });
      editorViewRef.current.dispatch(update);
    }
  }, [paneValue]);

  return <div ref={editorRef}></div>;
}

