import React, { useState, useEffect, useRef, createContext, useContext } from 'react';

// Создаем контекст для управления открытыми меню
const MenuContext = createContext();

const MenuProvider = ({ children }) => {
  const [openMenuId, setOpenMenuId] = useState(null);
  return (
    <MenuContext.Provider value={{ openMenuId, setOpenMenuId }}>
      {children}
    </MenuContext.Provider>
  );
};

const CampaignCard = ({ campaign }) => {
  const { openMenuId, setOpenMenuId } = useContext(MenuContext);
  const menuRef = useRef(null);
  const isMenuOpen = openMenuId === campaign.id;
  
  const total = campaign.campaign_customers?.length || 0;
  const sent = campaign.campaign_customers?.filter(cc => cc.status === 'sent').length || 0;
  const failed = campaign.campaign_customers?.filter(cc => cc.status === 'failed').length || 0;
  const pending = total - sent - failed;
  const sentPercentage = total > 0 ? Math.round((sent / total) * 100) : 0;

  // Обработчик клика вне меню
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenuId(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setOpenMenuId]);

  const handleDelete = async () => {
    if (confirm('Are you sure you want to delete this campaign?')) {
      try {
        const response = await fetch(`/campaigns/${campaign.id}`, {
          method: 'DELETE',
          headers: {
            'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
            'Accept': 'application/json' // Добавляем для поддержки JSON ответов
          },
          redirect: 'follow' // Явно указываем следовать редиректам
        });
  
        if (response.redirected) {
          // Если сервер вернул редирект - следуем ему
          window.location.href = response.url;
        } else if (response.ok) {
          // Если просто успешный ответ без редиректа
          window.location.href = '/campaigns';
        } else {
          // Если ошибка
          console.error('Delete failed:', response.statusText);
        }
      } catch (error) {
        console.error('Error deleting campaign:', error);
      }
    }
  };

  const toggleMenu = () => {
    setOpenMenuId(isMenuOpen ? null : campaign.id);
  };

  return (
    <div className="bg-white rounded-xl border border-slate-200/70 shadow-sm hover:shadow-md transition-all duration-200">
      <div className="p-6 flex items-center gap-6">
        {/* Campaign Type Icon */}
        <div className={`flex-shrink-0 w-12 h-12 rounded-lg flex items-center justify-center
          ${campaign.manual_ids ? 'bg-violet-50 text-violet-600' : 'bg-blue-50 text-blue-600'}`}
        >
          <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth="1.5" 
              d={campaign.manual_ids 
                ? "M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                : "M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0z"
              } 
            />
          </svg>
        </div>

        {/* Campaign Info */}
        <div className="flex-1 min-w-0">
          <div className="flex items-center justify-between">
            <h3 className="text-base font-semibold text-slate-900 truncate">{campaign.name}</h3>
          </div>

          <div className="mt-1 flex items-center gap-2 text-sm text-slate-500">
            {campaign.sender_name && (
              <>
                <span>{campaign.sender_name}</span>
                <span className="text-slate-300">•</span>
              </>
            )}
            <span>{campaign.created_at_ago}</span>
          </div>

          <div className="mt-2">
            {campaign.manual_ids ? (
              <span className="inline-flex px-2.5 py-0.5 text-xs font-medium bg-violet-50 text-violet-700 rounded-full">
                Manual List · {total} recipients
              </span>
            ) : (
              <div className="flex gap-2">
                <span className="inline-flex px-2.5 py-0.5 text-xs font-medium bg-blue-50 text-blue-700 rounded-full">
                  {campaign.country} · {total} recipients · Min. {campaign.deposits_count} deposits
                </span>
                {campaign.last_deposit_period && (
                  <span className="inline-flex items-center gap-1 text-xs text-slate-500">
                    <svg className="w-3.5 h-3.5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" 
                      />
                    </svg>
                    {campaign.last_deposit_period}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>

        {/* Status Section */}
        <div className="flex items-center gap-6 pl-6 border-l border-slate-200">
          {/* Progress Stats */}
          <div className="flex items-center gap-4">
            {/* Progress Circle with enhanced animation */}
            <div className="relative w-14 h-14">
              <svg className="w-full h-full -rotate-90" viewBox="0 0 36 36">
                <circle cx="18" cy="18" r="16" className="stroke-slate-100" strokeWidth="2.5" fill="none"/>
                <circle cx="18" cy="18" r="16" 
                  className={`${sentPercentage === 100 ? 'stroke-emerald-500' : 'stroke-blue-500'} transition-all duration-1000`}
                  strokeWidth="2.5"
                  strokeDasharray={`${sentPercentage}, 100`}
                  strokeLinecap="round"
                  fill="none"
                />
              </svg>
              <div className="absolute inset-0 flex items-center justify-center">
                <span className="text-sm font-semibold text-slate-700">{sentPercentage}%</span>
              </div>
              {/* Enhanced pending animation */}
              {pending > 0 && (
                <div className="absolute -top-1 -right-1 flex items-center justify-center">
                  <span className="relative flex h-3 w-3">
                    <span className="absolute inline-flex w-full h-full rounded-full bg-blue-400 animate-ping opacity-75"></span>
                    <span className="relative inline-flex w-3 h-3 rounded-full bg-blue-500">
                      <span className="absolute inset-0 rounded-full bg-blue-400 animate-pulse"></span>
                    </span>
                  </span>
                </div>
              )}
            </div>

            {/* Compact Stats */}
            <div className="flex flex-col gap-1 min-w-[100px]">
              <div className="flex items-center gap-2">
                <div className="w-2 h-2 rounded-full bg-emerald-500"></div>
                <span className="text-sm text-slate-600">{sent} sent</span>
              </div>
              {pending > 0 && (
                <div className="flex items-center gap-2">
                  <div className="relative w-2 h-2">
                    <div className="absolute w-2 h-2 rounded-full bg-blue-500 animate-pulse"></div>
                  </div>
                  <span className="text-sm text-slate-600">{pending} pending</span>
                </div>
              )}
              {failed > 0 && (
                <div className="flex items-center gap-2">
                  <div className="w-2 h-2 rounded-full bg-rose-500"></div>
                  <span className="text-sm text-slate-600">{failed} failed</span>
                </div>
              )}
            </div>
          </div>

          {/* Send Time Badge */}
          {campaign.send_time === 'later' ? (
            <div className="flex items-center gap-2 min-w-[120px]">
              <svg className="w-4 h-4 text-amber-600" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" 
                />
              </svg>
              <span className="text-sm font-medium text-amber-600">
                {campaign.scheduled_time}
              </span>
            </div>
          ) : (
            <div className="flex items-center gap-2 min-w-[120px]">
              <svg className="w-4 h-4 text-emerald-600" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                  d="M13 10V3L4 14h7v7l9-11h-7z" 
                />
              </svg>
              <span className="text-sm font-medium text-emerald-600">
                Immediate
              </span>
            </div>
          )}

          {/* Actions Menu */}
          <div className="relative" ref={menuRef}>
            <button 
              onClick={toggleMenu}
              className="p-2 rounded-lg text-slate-400 hover:text-slate-600 hover:bg-slate-100"
            >
              <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                  d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" 
                />
              </svg>
            </button>

            {isMenuOpen && (
              <div className="absolute right-0 mt-2 w-48 rounded-lg bg-white shadow-lg ring-1 ring-slate-200 z-10">
                <div className="py-1">
                  {pending > 0 && (
                    <button 
                      disabled
                      className="flex w-full items-center px-4 py-2 text-sm text-slate-400 cursor-not-allowed"
                    >
                      <svg className="w-4 h-4 mr-3" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                          d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" 
                        />
                      </svg>
                      Pause Campaign
                    </button>
                  )}
                  
                  <a 
                    href={`/campaigns/${campaign.id}`}
                    className="flex w-full items-center px-4 py-2 text-sm text-slate-700 hover:bg-slate-50"
                  >
                    <svg className="w-4 h-4 mr-3" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                        d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" 
                      />
                    </svg>
                    View Analytics
                  </a>

                  <button 
                    className="flex w-full items-center px-4 py-2 text-sm text-rose-600 hover:bg-rose-50"
                    onClick={handleDelete}
                  >
                    <svg className="w-4 h-4 mr-3" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" 
                      />
                    </svg>
                    Delete Campaign
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// Оборачиваем компонент в провайдер контекста
const CampaignCardWithContext = (props) => (
  <MenuProvider>
    <CampaignCard {...props} />
  </MenuProvider>
);

export default CampaignCardWithContext;