// components/AudienceFilters.jsx
import React from 'react';
import Select from 'react-select';

const AudienceFilters = ({ formData, countries, onChange, disabled }) => {
  const countryOptions = countries.map(country => ({
    value: country,
    label: country
  }));

  const handleChange = (field, value) => {
    onChange({ ...formData, [field]: value });
  };

  return (
    <form className={`space-y-4 ${disabled ? 'opacity-50 pointer-events-none' : ''}`}>
      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Country *
        </label>
        <Select
          value={formData.country}
          onChange={(option) => handleChange('country', option)}
          options={countryOptions}
          isDisabled={disabled}
        />
      </div>

      <div className="flex space-x-4">
        <div className="flex-1">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Last Deposit Start *
          </label>
          <input
            type="date"
            value={formData.lastDepositStart}
            onChange={(e) => handleChange('lastDepositStart', e.target.value)}
            disabled={disabled}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div className="flex-1">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Last Deposit End *
          </label>
          <input
            type="date"
            value={formData.lastDepositEnd}
            onChange={(e) => handleChange('lastDepositEnd', e.target.value)}
            disabled={disabled}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Number of Deposits
        </label>
        <input
          type="number"
          value={formData.depositsCount}
          onChange={(e) => handleChange('depositsCount', e.target.value)}
          disabled={disabled}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
    </form>
  );
};

export default AudienceFilters;