// CampaignForm.jsx
import React, { useState } from 'react';
import AudienceStep from './steps/AudienceStep';
import CampaignSettings from './steps/CampaignSettings';
import MessageContent from './steps/MessageContent';
import StepNavigation from './components/StepNavigation';

const CampaignForm = ({ countries, senders }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [isFormValid, setIsFormValid] = useState(false);
  const [formData, setFormData] = useState({
    // Audience data
    country: null,
    lastDepositStart: '',
    lastDepositEnd: '',
    depositsCount: '',
    ban: false,
    manualIds: '',
    audienceCount: 0,
    
    // Campaign settings
    name: '',
    sender: null,
    sendTime: 'now',
    scheduledTime: '',
    
    // Message content
    messageText: '',
    messageLink: ''
  });

  console.log(senders);

  const updateFormData = (newData) => {
    setFormData(prev => ({ ...prev, ...newData }));
  };

  const handleStepChange = (step) => {
    setCurrentStep(step);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      const response = await fetch('/campaigns', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({ campaign: formData })
      });

      if (response.ok) {
        window.location.href = '/campaigns';
      } else {
        const errorData = await response.json();
        console.error('Error creating campaign:', errorData);
      }
    } catch (error) {
      console.error('Error:', error);
      setIsSubmitting(false);
    }
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <AudienceStep
            formData={formData}
            updateFormData={updateFormData}
            countries={countries}
            setIsFormValid={setIsFormValid}
          />
        );
      case 2:
        return (
          <CampaignSettings
            formData={formData}
            updateFormData={updateFormData}
            senders={senders}
            setIsFormValid={setIsFormValid}
          />
        );
      case 3:
        return (
          <MessageContent
            formData={formData}
            updateFormData={updateFormData}
            setIsFormValid={setIsFormValid}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-xl relative">
      <h2 className="text-2xl font-bold mb-6">Create Campaign</h2>

      {isSubmitting && (
        <div className="absolute inset-0 bg-white/60 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="text-center">
            <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
            <div className="text-xl font-semibold text-gray-800 animate-pulse">
              Creating your campaign...
            </div>
            <div className="text-sm text-gray-600 mt-2">
              Please wait while we process your request
            </div>
          </div>
        </div>
      )}
      
      <StepNavigation
        currentStep={currentStep}
        onStepChange={handleStepChange}
        isFormValid={isFormValid}
      />

      {renderCurrentStep()}

      <div className="flex justify-between mt-6">
        {currentStep > 1 && (
          <button
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={() => handleStepChange(currentStep - 1)}
          >
            Previous
          </button>
        )}
        
        {currentStep < 3 ? (
          <button
            className={`bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
              !isFormValid && 'opacity-50 cursor-not-allowed'
            }`}
            onClick={() => handleStepChange(currentStep + 1)}
            disabled={!isFormValid}
          >
            Next
          </button>
        ) : (
          <button
            className={`bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-all duration-300 ${
              !isFormValid || isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
            } ${isSubmitting ? 'scale-95' : ''}`}
            onClick={handleSubmit}
            disabled={!isFormValid || isSubmitting}
          >
            {isSubmitting ? (
              <span className="flex items-center">
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                </svg>
                Creating...
              </span>
            ) : 'Create Campaign'}
          </button>
        )}
      </div>
    </div>
  );
};

export default CampaignForm;