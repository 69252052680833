import React from 'react';

const SearchAudienceButton = ({
  isEnabled,
  isLoading,
  hasSearched,
  audienceCount,
  error,
  onSearch
}) => {
  if (isLoading) {
    return (
      <div className="text-center p-4">
        <div className="flex items-center justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
          <span className="ml-3 text-gray-600">Searching for audience...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center p-4 bg-red-50 rounded-lg">
        <div className="flex items-center justify-center text-red-600">
          <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <span>{error}</span>
        </div>
        <button
          onClick={() => onSearch()}
          className="mt-2 text-sm text-red-600 hover:text-red-800 underline focus:outline-none"
        >
          Try again
        </button>
      </div>
    );
  }

  if (hasSearched && audienceCount > 0) {
    return (
      <div className="text-center p-4 bg-gray-50 rounded-lg">
        <div className="mb-2">
          <span className="text-lg font-semibold">Found Audience: </span>
          <span className="text-blue-600 font-bold text-xl">
            {audienceCount.toLocaleString()}
          </span>
        </div>
        <p className="text-sm text-gray-500">
          Change filters or manual IDs to search again
        </p>
      </div>
    );
  }

  if (hasSearched && audienceCount === 0) {
    return (
      <div className="text-center p-4 bg-red-50 rounded-lg">
        <div className="mb-2">
          <span className="text-lg font-semibold text-red-600">Found Audience: </span>
          <span className="text-red-600 font-bold text-xl">
            0
          </span>
        </div>
        <p className="text-sm text-red-500">
          Please adjust your search criteria or manual IDs list
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center space-y-2">
      <button
        onClick={onSearch}
        disabled={!isEnabled}
        className={`
          w-full py-3 px-4 rounded-lg font-semibold text-white 
          transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2
          ${isEnabled
            ? 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-500'
            : 'bg-gray-300 cursor-not-allowed'
          }
        `}
      >
        Search Audience
      </button>
      {!isEnabled && (
        <p className="text-sm text-gray-500">
          Fill in all required fields to enable search
        </p>
      )}
    </div>
  );
};

export default SearchAudienceButton;