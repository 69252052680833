import React, { useState, useEffect, useRef } from 'react';

const ResponseCondition = ({ response, updateFormData, senderData }) => {
    const [dataType, setDataType] = useState(senderData?.search_conditions?.responseCondition?.dataType || 'json');
    const [selector, setSelector] = useState(senderData?.search_conditions?.responseCondition?.selector || '');
    const [successCondition, setSuccessCondition] = useState(senderData?.search_conditions?.responseCondition?.successCondition || '');

    const [notification, setNotification] = useState(null);

    useEffect(() => {
      if (senderData) {
        console.log('senderData в ResponseCondition:', senderData);
        setDataType(senderData.search_conditions?.responseCondition?.dataType || 'json');
        setSelector(senderData.search_conditions?.responseCondition?.selector || '');
        setSuccessCondition(senderData.search_conditions?.responseCondition?.successCondition || '');
      }
    }, [senderData]);


    useEffect(() => {
      updateFormData({ dataType, selector, successCondition });
    }, [dataType, selector, successCondition]);


    const handleCheck = (response) => {
        setNotification(null);

        updateFormData({ dataType, selector, successCondition });

        if (dataType === 'html') {
            var responseText;

            console.log(dataType);
            
            if (typeof response === 'object') {
              // Если response является объектом, используем его напрямую
              responseText = JSON.stringify(response).toLowerCase();
            } else {
              // Если response не является объектом, используем его напрямую
              responseText = response.toString().toLowerCase();
            }

            // Теперь responseText содержит строковое представление response в нижнем регистре
            if (responseText.includes(successCondition.toLowerCase())) {
                displayNotification('Успех! HTML условие выполнено.', 'success');
            } else {
                displayNotification('Ошибка! HTML условие не выполнено.', 'error');
            }
        } else if (dataType === 'json') {
            try {
              let jsonData;

              if (typeof response === 'object') {
                // Если response уже является объектом, используем его напрямую
                jsonData = response.data || response;
              } else {
                // Попробуем парсить как JSON
                jsonData = JSON.parse(response.data || response);

                // Если парсинг успешен, jsonData теперь содержит объект JSON
              }

              const selectedValue = getValueBySelector(jsonData, selector);

              if (selectedValue == successCondition) {
                displayNotification('Успех! JSON условие выполнено.', 'success');
              } else {
                const message = (
                    <div>
                      <span className="font-bold">Ошибка! </span>
                      <div>
                        <span className="font-bold">Ожидаем условие: </span>
                        {successCondition}
                      </div>
                      <div>
                        <span className="font-bold">Вывод селектора: </span>
                        {JSON.stringify(selectedValue)}
                      </div>
                    </div>
                  );
                displayNotification(message, 'error', successCondition, selectedValue);
              }
            } catch (error) {
              displayNotification('Ошибка при проверке условия.', 'error');
            }
        }
    };

    // Функция для получения значения по селектору в JSON
    const getValueBySelector = (data, selector) => {
        const keys = selector.split(',');
        let selectedValue = data;
    
        for (const key of keys) {
            const trimmedKey = key.trim();
        
            if (selectedValue.hasOwnProperty(trimmedKey)) {
                selectedValue = selectedValue[trimmedKey];
            } else {
                return null; // Если ключ не существует в данных, возвращаем null
            }
        }
    
        return selectedValue;
    };

    const displayNotification = (message, type, condition, selectorCondition) => {
        setNotification({ message, type, condition, selectorCondition });
      };
    
      const closeNotification = () => {
        setNotification(null);
      };

    return (
      <div className="my-4">
        <span className="text-2xl font-medium">Check success</span>
        <div className="flex px-4 py-4 rounded-b-lg border border-gray-300 mt-5 items-end">
            <div className="w-1/5 mr-2">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                    Data type
                </label>
                <div>
                    <select
                        className="w-full px-4 py-2 border rounded border-gray-300 hover:border-orange-500 focus:outline-none bg-gray-100"
                        value={dataType}
                        onChange={(e) => setDataType(e.target.value)}
                    >
                        <option value="json">JSON</option>
                        <option value="html">HTML</option>
                    </select>
                </div>
            </div>
  
            <div className="w-2/5 mr-2">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                    Selector
                </label>
                <div>
                    <input
                        type="text"
                        className="px-4 py-1.5 w-full border border-gray-300 rounded-md  hover:border-orange-500 focus:outline-orange-500"
                        value={selector}
                        onChange={(e) => setSelector(e.target.value)}
                    />
                </div>
            </div>
  
            <div className="w-2/5 mr-2">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                    Success condition
                </label>
                <div>
                    <input
                        type="text"
                        className="px-4 py-1.5 w-full border border-gray-300 rounded-md  hover:border-orange-500 focus:outline-orange-500"
                        value={successCondition}
                        onChange={(e) => setSuccessCondition(e.target.value)}
                    />
                </div>
            </div>
  
            <button
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 w-1/5 h-full"
                onClick={() => handleCheck(response)}
            >
            Check
            </button>
        </div>
            {notification && (
            <div className={`mt-4 p-2 rounded ${notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'}`}>
            <span className="text-white">{notification.message}</span>
            <button className="ml-2 text-white" onClick={closeNotification}>
                &#10006; {/* Значок крестика */}
            </button>
            </div>
        )}
      </div>
    );
  };

export default ResponseCondition;