import React, { useState, useEffect } from 'react';

const MessageIdForm = ({ updateFormData, response, senderData }) => {
  const [showForm, setShowForm] = useState(false);
  const [dataType, setDataType] = useState('json');
  const [selector, setSelector] = useState('');
  const [result, setResult] = useState('');

  useEffect(() => {
    if (senderData) {
        console.log('senderData в MessageIdForm:', senderData);
        setDataType(senderData.message_id_find?.dataType || 'json');
        setSelector(senderData.message_id_find?.selector || '');
        // showForm = true;
        setShowForm(true);
    }
    }, [senderData]);

  const handleAddMessageId = () => {
    setShowForm(true);
  };

  const handleCheckAndSave = () => {
    let extractedValue = '';
  
    if (dataType === 'json' && response) {
      try {
        const responseData = typeof response === 'string' ? JSON.parse(response.data) : response.data;
  
        // Извлекаем данные по селектору
        const keys = selector.split(', '); // Разделяем селектор по точкам для вложенного доступа
        extractedValue = keys.reduce((acc, key) => acc[key], responseData);



        // Проверяем, было ли что-то найдено
        if (extractedValue === undefined) {
          extractedValue = 'Not found';
        }
      } catch (error) {
        console.error('Error parsing JSON:', error);
        extractedValue = 'Error parsing JSON';
      }
    }

    // todo: добавить обработку для HTML
    if (dataType === 'html' && response) {
      // селектор должен быть javascript регуляркой
      // https://developer.mozilla.org/ru/docs/Web/JavaScript/Guide/Regular_Expressions
      // https://regex101.com/

      try {
        const responseData = typeof response === 'string' ? response.data : response.data;
        console.log(responseData);
        console.log(selector);
        const re = new RegExp(selector);
        const matches = responseData.match(re);
        extractedValue = matches ? matches[0] : 'Not found';
      } catch (error) {
        console.error('Error parsing JSON:', error);
        extractedValue = 'Error parsing HTML with RegExp';
      }
    }

    console.log('dataType:', dataType);
    console.log('selector:', selector);
  
    setResult(`Result: ${extractedValue}`);
    updateFormData({ dataType, selector });
  };

  useEffect(() => {
    updateFormData({ dataType, selector });
  }, [dataType, selector]);
  

  return (
    <div className="p-4 border border-gray-300 rounded shadow-sm">
      {!showForm && (
        <button
          onClick={handleAddMessageId}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Add message_id
        </button>
      )}
      {showForm && (
        <div className="space-y-4">
          {/* Форма */}
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">DataType</label>
            <select
              className="block w-full bg-white border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              value={dataType}
              onChange={e => setDataType(e.target.value)}
            >
              <option value="json">JSON</option>
              <option value="html">HTML</option>
            </select>
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">Selector</label>
            <input
              type="text"
              className="block w-full bg-white border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              value={selector}
              onChange={e => setSelector(e.target.value)}
            />
          </div>
          <button
            onClick={handleCheckAndSave}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
            Check and Save
          </button>

          {/* Результат */}
          {result && <div className="mt-2 text-green-600">{result}</div>}
        </div>
      )}
    </div>
  );
};

export default MessageIdForm;
