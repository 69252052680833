import React from 'react';

const requestMethods = [
  {
    slug: 'get',
    method: 'GET',
  },
  {
    slug: 'post',
    method: 'POST',
  },
  {
    slug: 'put',
    method: 'PUT',
  },
  {
    slug: 'patch',
    method: 'PATCH',
  },
  {
    slug: 'delete',
    method: 'DELETE',
  },
];

export default function UrlEditor({
  url,
  setUrl,
  reqMethod,
  setReqMethod,
  onInputSend,
  testPhoneNumber,
  setTestPhoneNumber,
  testMessage,
  setTestMessage,
}) {
  return (
    <>
      <form className='flex'>
        <select
          className='px-4 py-2 border rounded-md border-gray-300 hover:border-orange-500 focus:outline-none bg-gray-100'
          value={reqMethod}
          onChange={(e) => setReqMethod(e.target.value)}
        >
          {requestMethods.map((option) => (
            <option key={option.slug} value={option.method}>
              {option.method}
            </option>
          ))}
        </select>
        <input
          className='ml-3 w-full px-4 py-2 border rounded-md border-gray-300 hover:border-orange-500 focus:outline-orange-500'
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <input
          type="text"
          id="testPhoneNumber"
          value={testPhoneNumber}
          onChange={(e) => setTestPhoneNumber(e.target.value)}
          placeholder="Test Phone Number"
          className="ml-3 px-4 py-2 border rounded-md border-gray-300 hover:border-orange-500 focus:outline-orange-500"
        />
        <input
        type="text"
        id="testMessage"
        value={testMessage}
        onChange={(e) => setTestMessage(e.target.value)}
        placeholder="Test Message"
        className="ml-3 px-4 py-2 border rounded-md border-gray-300 hover:border-orange-500 focus:outline-orange-500"
      />
        <button
          className='ml-3 px-6 py-2 rounded-md font-semibold text-white bg-orange-500 hover:bg-orange-600'
          type='button'
          onClick={(e) => onInputSend(e)}
        >
          Send
        </button>
      </form>
    </>
  );
}
