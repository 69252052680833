// steps/MessageContent.jsx
import React, { useEffect, useState } from 'react';

const MessageContent = ({ formData, updateFormData, setIsFormValid }) => {
  const [charCount, setCharCount] = useState(formData.messageText.length);
  const MAX_CHARS = 160;

  useEffect(() => {
    setIsFormValid(!!formData.messageText.trim());
  }, [formData.messageText, setIsFormValid]);

  const handleMessageChange = (value) => {
    if (value.length <= MAX_CHARS) {
      updateFormData({ messageText: value });
      setCharCount(value.length);
    }
  };

  return (
    <div className="space-y-6">
      <div>
        <div className="flex justify-between items-center mb-2">
          <label className="block text-gray-700 text-sm font-bold" htmlFor="messageText">
            Message Text *
          </label>
          <span className={`text-sm ${charCount > MAX_CHARS - 20 ? 'text-red-500' : 'text-gray-500'}`}>
            {charCount}/{MAX_CHARS} characters
          </span>
        </div>
        <textarea
          id="messageText"
          value={formData.messageText}
          onChange={(e) => handleMessageChange(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          rows="4"
          placeholder="Enter your message text here..."
        />
      </div>

      <div className="mt-8">
        <h4 className="text-gray-700 font-bold mb-3">Message Preview</h4>
        <div className="bg-gray-100 p-4 rounded-lg">
          <div className="bg-white rounded-lg p-4 shadow-sm max-w-sm mx-auto">
            <div className="text-gray-900 whitespace-pre-wrap">
              {formData.messageText || 'Your message will appear here...'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageContent;