import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { ChevronLeftIcon, ChevronRightIcon, Search, Calendar, Globe, Info } from 'lucide-react';

const Tooltip = ({ children, content }) => {
    const [isVisible, setIsVisible] = useState(false);
  
    return (
      <div className="relative inline-block">
        <div
          onMouseEnter={() => setIsVisible(true)}
          onMouseLeave={() => setIsVisible(false)}
        >
          {children}
        </div>
        {isVisible && (
          <div className="absolute z-10 w-96 p-3 mt-2 text-sm text-white bg-gray-800 rounded-lg shadow-lg">
            {content}
          </div>
        )}
      </div>
    );
  };

const CustomersTable = ({ initialCustomers, initialTotalPages, initialCurrentPage, initialTotalCount, countries }) => {
  const [customers, setCustomers] = useState(initialCustomers);
  const [totalPages, setTotalPages] = useState(initialTotalPages);
  const [currentPage, setCurrentPage] = useState(initialCurrentPage);
  const [totalCount, setTotalCount] = useState(initialTotalCount);
  const [filters, setFilters] = useState({
    phone: '',
    country: '',
    registration_date: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const countryOptions = [
    { value: '', label: 'All Countries' },
    ...countries.map(country => ({ value: country, label: country }))
  ];

  const fetchCustomers = async (page, appliedFilters) => {
    setIsLoading(true);
    const params = new URLSearchParams({ 
      page, 
      ...appliedFilters
    });
    try {
      const response = await fetch(`/api/v1/customers?${params}`);
      const data = await response.json();
      setCustomers(data.customers);
      setTotalPages(data.total_pages);
      setCurrentPage(data.current_page);
      setTotalCount(data.total_count);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
    setIsLoading(false);
  };

  const handleFilterChange = (name, value) => {
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleApplyFilters = () => {
    fetchCustomers(1, filters);
  };

  const handleResetFilters = () => {
    setFilters({
      phone: '',
      country: '',
      registration_date: '',
    });
    fetchCustomers(1, {});
  };

  const handlePageChange = (newPage) => {
    fetchCustomers(newPage, filters);
  };

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '42px',
      height: '42px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '42px',
      padding: '0 6px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '42px',
    }),
  };

  return (
    <div className="w-full">
      <div className="mb-6 bg-white p-4 rounded-lg shadow">
      <h2 className="text-lg font-semibold mb-4">Filter Customers</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-baseline">
          <div className="flex flex-col">
            <div className="relative flex items-center">
              <Search className="absolute left-3 text-gray-400" size={20} />
              <input
                type="text"
                placeholder="Search by Phone"
                value={filters.phone}
                onChange={(e) => handleFilterChange('phone', e.target.value)}
                className="pl-10 pr-3 py-2 w-full h-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="flex items-center mt-1 text-sm text-gray-500">
              <Tooltip content={
                <div>
                  <p className="mb-2">Use * to replace any number of characters in the phone number.</p>
                  <p className="font-semibold mb-1">Examples:</p>
                  <ul className="list-disc list-inside space-y-1">
                    <li>123* - finds numbers starting with 123</li>
                    <li>*456 - finds numbers ending with 456</li>
                    <li>123*456 - finds numbers starting with 123 and ending with 456</li>
                    <li>1*2*3 - finds numbers starting with 1, ending with 3, and having 2 somewhere in between</li>
                  </ul>
                </div>
              }>
                <Info className="w-4 h-4 mr-1 cursor-pointer" />
              </Tooltip>
              <span>Use * as wildcard</span>
            </div>
          </div>
          <div className="relative flex items-center">
            <Globe className="absolute left-3 text-gray-400 z-10" size={20} />
            <Select
              className="w-full"
              placeholder="Select Country"
              isClearable
              options={countryOptions}
              value={countryOptions.find(option => option.value === filters.country) || null}
              onChange={(option) => handleFilterChange('country', option ? option.value : '')}
              styles={{
                ...customSelectStyles,
                container: (provided) => ({
                  ...provided,
                  paddingLeft: '2.5rem',
                }),
              }}
            />
          </div>
          <div className="relative flex items-center">
            <Calendar className="absolute left-3 text-gray-400 z-10" size={20} />
            <input
              type="date"
              value={filters.registration_date}
              onChange={(e) => handleFilterChange('registration_date', e.target.value)}
              className="pl-10 pr-3 w-full h-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
        <div className="mt-4 flex justify-end space-x-2">
            <button
            onClick={handleApplyFilters}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
            disabled={isLoading}
            >
            {isLoading ? 'Loading...' : 'Apply Filters'}
            </button>
            <button
            onClick={() => {
                setFilters({ phone: '', country: '', registration_date: '' });
                fetchCustomers(1);
            }}
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
            disabled={isLoading}
            >
            Reset Filters
            </button>
        </div>
      </div>

      {isLoading ? (
        <div className="text-center py-4">Loading...</div>
      ) : (
        <>
        
          <div className="w-full overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3">ID</th>
                  <th scope="col" className="px-6 py-3">Phone</th>
                  <th scope="col" className="px-6 py-3">Email</th>
                  <th scope="col" className="px-6 py-3">Country</th>
                  <th scope="col" className="px-6 py-3">First Name</th>
                  <th scope="col" className="px-6 py-3">Main Currency</th>
                  <th scope="col" className="px-6 py-3">Registration Date</th>
                </tr>
              </thead>
              <tbody>
                {customers.map((customer) => (
                  <tr key={customer.id} className="bg-white border-b hover:bg-gray-50">
                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">{customer.id}</td>
                    <td className="px-6 py-4">{customer.phone}</td>
                    <td className="px-6 py-4">{customer.email}</td>
                    <td className="px-6 py-4">{customer.country}</td>
                    <td className="px-6 py-4">{customer.first_name}</td>
                    <td className="px-6 py-4">{customer.main_currency}</td>
                    <td className="px-6 py-4">{new Date(customer.created_at).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden">
              <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
                Previous
              </button>
              <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
                Next
              </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing page <span className="font-medium">{currentPage}</span> of <span className="font-medium">{totalPages}</span> | Total customers: <span className="font-medium">{totalCount}</span>
                </p>
              </div>
              <div>
                <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                  <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CustomersTable;