import React from 'react';

const HelloWorld = (props) => {
  return (
    <div>
      Hello, {props.name}!
    </div>
  );
};

export default HelloWorld;